<template lang="">
  <div>
    <v-container>
      <v-card :elevation="1" class="v-sheet theme--light br-0">
        <div class="row mt-0 mb-4">
          <div
            class="col-12 col-md-12 m-0 p-0"
            style="margin-top: -5px !important;"
          >
            <DivisorColor :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`" />
          </div>
        </div>
        <v-card-title>
          <v-row class="col-md-12">
            <v-col cols="12" sm="12" md="12">
              <p class="text-h5 font-weight-black mb-0">
                Reporte IAFF/CONVENIOS/DICTAMENES TÉCNICOS
              </p>
              <!--<v-subheader class="pl-0 font-weight-black text-subtitle-1 text--blue-grey text--lighten-4">
                                
                            </v-subheader>-->
            </v-col>
          </v-row>

          <v-row class="col-md-12 m-0 p-0">
            <v-divider></v-divider>
          </v-row>
        </v-card-title>
        <v-card-text class="pl-0 pr-0">
          <v-container class="p-8">
            <v-form
              ref="formDatosFinancieros"
              v-on:submit.prevent="buscarIaff"
              v-model="validFormBusqueda"
            >
              <v-row>
                <v-col cols="12" md="8" sm="12" xs="12" class="pt-0 pb-0">
                  <v-text-field
                    :clearable="true"
                    filled
                    autocomplete="off"
                    dense
                    v-model="nombreProyecto"
                    :disabled="btnBusquedaLoading"
                    label="Nombre del proyecto"
                    :rules="[maxLength('nombre del proyecto', 100)]"
                    maxlength="100"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="4" sm="12" xs="12" class="pt-0 pb-0">
                  <v-text-field
                    :clearable="true"
                    filled
                    autocomplete="off"
                    dense
                    v-model="codigoProyecto"
                    :disabled="btnBusquedaLoading"
                    label="Código del proyecto SICOOPERA/SIGEACI"
                    :rules="[maxLength('código del proyecto SICOOPERA', 20)]"
                    maxlength="20"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="8" sm="12" xs="12" class="pt-0 pb-0">
                  <v-autocomplete
                    v-model="entidadSeleccionada"
                    :items="instituciones"
                    class=""
                    :clearable="true"
                    dense
                    filled
                    :loading="ddInstitucionesLoading"
                    :disabled="btnBusquedaLoading"
                    label="Institución"
                    return-object
                    :item-text="
                      item =>
                        item.sigla
                          ? `${item.nombreInstitucion} (${item.sigla})`
                          : item.nombreInstitucion
                    "
                    item-value="id"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4" sm="6" xs="12" class="pt-0 pb-0">
                  <v-select
                    :clearable="true"
                    v-model="fuenteCooperanteSeleccionada"
                    :items="fuentesCooperantes"
                    :loading="ddFuentesCooperantesLoading"
                    :disabled="btnBusquedaLoading"
                    dense
                    filled
                    label="Fuente cooperante"
                    :no-data-text="
                      'No se encontraron fuentes cooperantes registradas'
                    "
                    menu-props="auto"
                    return-object
                    :item-text="
                      item => item.tipoFuenteCooperante + ' - ' + item.nombre
                    "
                    item-value="id"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4" sm="6" xs="12" class="pt-0 pb-0">
                  <v-select
                    :clearable="false"
                    v-model="datosBusqueda.tipoConsulta"
                    :items="tiposBusqueda"
                    :loading="false"
                    :disabled="btnBusquedaLoading"
                    dense
                    class="required"
                    filled
                    label="Tipo de consulta"
                    :no-data-text="'No se encontraron tipos de consulta'"
                    menu-props="auto"
                    item-text="tipo"
                    item-value="id"
                    :rules="[selectRequired('tipo de consulta')]"
                  ></v-select>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                  xs="12"
                  class="pt-0 pb-0"
                  v-if="
                    datosBusqueda.tipoConsulta === 1 ||
                      datosBusqueda.tipoConsulta === 2
                  "
                >
                  <v-select
                    dense
                    filled
                    v-model="datosBusqueda.periodoId"
                    :disabled="btnBusquedaLoading"
                    :items="periodos"
                    class="required"
                    color="blue-grey lighten-2"
                    label="Año"
                    item-text="periodo"
                    item-value="id"
                    menu-props="auto"
                  >
                  </v-select>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                  xs="12"
                  class="pt-0 pb-0"
                  v-if="
                    datosBusqueda.tipoConsulta === 1 ||
                      datosBusqueda.tipoConsulta === 2
                  "
                >
                  <v-select
                    dense
                    filled
                    v-model="datosBusqueda.bimestreId"
                    :disabled="btnBusquedaLoading"
                    :items="bimestres"
                    class="required"
                    color="blue-grey lighten-2"
                    label="Bimestre"
                    item-text="nombreBimestre"
                    item-value="id"
                    menu-props="auto"
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                  <v-btn
                    class="white--text float-right  mr-1 mb-2"
                    x-large
                    color="light-blue-502"
                    :loading="btnBusquedaLoading"
                    :disabled="!validFormBusqueda || btnBusquedaLoading"
                    type="submit"
                  >
                    <v-icon left>mdi-magnify</v-icon> Buscar
                  </v-btn>

                  <v-btn
                    color="grey lighten-5"
                    elevation="0"
                    class="mb-2 float-right grey lighten-4 mr-2"
                    :loading="false"
                    x-large
                    :disabled="btnBusquedaLoading"
                    @click="limpiarFiltros()"
                  >
                    <v-icon>mdi-trash</v-icon>
                    Limpiar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>

            <v-row>
              <v-col cols="12" md="12" sm="12" class="pt-0 pb-3">
                <v-subheader class="text-h5 black--text">
                  Resultados obtenidos
                </v-subheader>
                <hr />
              </v-col>
            </v-row>

            <v-row v-if="consultaFinalizada == true">
              <v-col cols="12" md="12">
                <v-data-table
                  class="elevation-0"
                  :headers="headersBimestreAnio"
                  :header-props="{
                    sortByText: 'Ordenar por',
                  }"
                  :items="proyectos"
                  @current-items="proyectosFiltrados"
                  :search="filtro"
                  :loading="tableLoading"
                  no-data-text="No se encontraron registros"
                  loading-text="Cargando..."
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-page-first',
                    lastIcon: 'mdi-page-last',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    'items-per-page-text': 'Registros por página',
                    pageText: '{0}-{1} de {2}',
                  }"
                >
                  <template v-slot:top>
                    <!-- v-container, v-col and v-row are just for decoration purposes. -->
                    <v-container fluid>
                      <v-row>
                        <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                          <v-btn
                            class="ma-0 white--text d-none"
                            medium
                            color="light-blue darken-2"
                          >
                            <v-icon left>mdi-magnify</v-icon> Buscar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>

                  <template v-slot:item="{ item }">
                    <tr>
                      <td>
                        {{ item.codigo }}
                      </td>
                      <td>
                        {{ item.refSigeaci ? item.refSigeaci : "N/A" }}
                      </td>
                      <td>
                        {{ item.periodo }}
                      </td>
                      <td>
                        {{
                          datosBusqueda.tipoConsulta === 1 ||
                          datosBusqueda.tipoConsulta === 3
                            ? item.bimestre
                            : ""
                        }}
                      </td>
                      <td>{{ item.nombreProyecto }}</td>
                      <td>{{ item.fuenteCooperante }}</td>
                      <!--<td>{{ item.fechaSolicitudOpinionTecnica | formatDate }}</td>-->
                      <td>
                        {{ item.institucion }} <br />
                        {{ item.unidadEjecutora ? item.unidadEjecutora : `` }}
                      </td>
                      <td>{{ item.fechaSuscripcion }}</td>
                      <td>{{ item.fechaInicio }}</td>

                      <td>
                        <v-chip
                          label
                          class="ma-2"
                          :color="
                            item.estadoProyectoId === 1
                              ? 'blue-grey lighten-4'
                              : item.estadoProyectoId === 2
                              ? 'blue lighten-4'
                              : item.estadoProyectoId === 3
                              ? 'deep-orange lighten-4'
                              : item.estadoProyectoId === 5
                              ? 'cyan lighten-4'
                              : item.estadoProyectoId === 6
                              ? 'teal lighten-4'
                              : item.estadoProyectoId === 7
                              ? 'deep-orange lighten-4'
                              : item.estadoProyectoId === 8
                              ? 'red lighten-4'
                              : 'blue-grey lighten-4'
                          "
                          :text-color="
                            item.estadoProyectoId === 1
                              ? 'blue-grey lighten-1'
                              : item.estadoProyectoId === 2
                              ? 'blue lighten-1'
                              : item.estadoProyectoId === 3
                              ? 'deep-orange lighten-1'
                              : item.estadoProyectoId === 5
                              ? 'cyan lighten-1'
                              : item.estadoProyectoId === 6
                              ? 'teal lighten-1'
                              : item.estadoProyectoId === 7
                              ? 'deep-orange lighten-1'
                              : item.estadoProyectoId === 8
                              ? 'red lighten-1'
                              : 'blue-grey lighten-1'
                          "
                          small
                        >
                          {{ item.estadoProyecto }}
                        </v-chip>
                      </td>
                      <td>
                        <v-btn
                          v-if="item.pathIaff"
                          class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                          small
                          depressed
                          color="blue-grey lighten-5"
                          @click="
                            datosBusqueda.tipoConsulta == 1 ?
                             item.procedencia == 'SIGEACI'
                              ? descargarArchivo(item.pathIaff, 'sigeaci')
                              : descargarArchivo(item.pathIaff, 'sicoopera') :
                              datosBusqueda.tipoConsulta == 2 ? descargarArchivo(item.pathIaff, 'externo') : ''
                          "
                        >
                          <v-icon left>mdi-file-pdf-box</v-icon>{{datosBusqueda.tipoConsulta == 1 ? `Descargar IAFF` : `Descargar POA`}}
                        </v-btn>

                        <v-btn
                          v-if="datosBusqueda.tipoConsulta == 1  && item.pathResumenIaff"
                          class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                          small
                          depressed
                          color="blue-grey lighten-5"
                          @click="
                             item.procedencia == 'SICOOPERA'
                              ? descargarArchivo(item.pathResumenIaff, 'sicoopera')
                              : ``
                          "
                        >
                          <v-icon left>mdi-file-pdf-box</v-icon>Descargar Resumen Ejecutivo
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-container>

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
    ></DialogLoader>
    <!---->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
import DivisorColor from "@/view/content/biblioteca-componentes/DivisorColor.vue";
import DialogLoader from "@/view/content/DialogLoader";
import { REPORTE_DOCUEMNTOS_CNS } from "@/core/services/store/proyectoscns/proyectocns.module";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";
import DownloadFile from "@/core/untils/downloadfile.js";
export default {
  name: "ReporteDocumentosCns",
  components: {
    DivisorColor,
    DialogLoader,
    SnackAlert,
  },
  data() {
    return {
      validFormBusqueda: false,
      dialogLoaderVisible: false,
      dialogLoaderText: "",
      proyectos: [],
      proyectosFiltrados: [],
      nombreProyecto: "",
      codigoProyecto: "",
      entidadSeleccionada: null,
      instituciones: [],
      ddInstitucionesLoading: false,
      fuenteCooperanteSeleccionada: null,
      fuentesCooperantes: [],
      ddFuentesCooperantesLoading: false,
      periodos: [],
      ddPeriodosLoading: false,
      bimestres: [],
      ddBimestresLoading: false,
      btnBusquedaLoading: false,
      periodoId: 0,
      bimestreId: 0,
      filtro: "",
      datosBusqueda: {
        nombreProyecto: "",
        codigo: "",
        entidadId: null,
        tipoConsulta: 1,
        periodoId: 0,
        bimestreId: 0,
      },
      consultaFinalizada: false,
      tiposBusqueda: [
        { id: 1, tipo: "IAFF/Resumen Ejecutivo" },
        { id: 2, tipo: "Programación Anual" },
        /*{ id: 2, tipo: "Resumen Ejecutivo IAFF" },
        { id: 3, tipo: "Convenios" },
        { id: 4, tipo: "Dictamen Técnico" },
        { id: 5, tipo: "Autorización Cierre" },
        { id: 6, tipo: "Autorización Suspensión" },
        { id: 7, tipo: "Autorización Finalización" },
        { id: 8, tipo: "Enmiendas" },*/
      ],
      tipoConsulta: 0,

      ...validations,
    };
  },

  methods: {
    //Obtener tipos de datos financieros
    async obtenerFuentesCooperantes() {
      this.fuentesCooperantes = [];

      this.ddFuentesCooperantesLoading = true;

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {
          endpoint: "FuentesCooperantes/all/1",
        })
        .then(res => {
          if (res.status === 200) {
            this.fuentesCooperantes = res.data;
            this.fuentesCooperantes.splice(0, 0, {
              nombre: "TODOS",
              id: 0,
              estadosId: 1,
              estado: "Activo",
              tipoFuenteCooperante: "N"
            });
          }
          this.ddFuentesCooperantesLoading = false;
        })
        .catch(() => {
          this.fuentesCooperantes = [];
          this.ddFuentesCooperantesLoading = false;
        });
    },

    //Obtener entidades o instituciones
    async obtenerEntidades() {
      this.instituciones = [];
      this.ddInstitucionesLoading = true;
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: `Entidades/all/1` })
        .then(res => {
          if (res.status === 200) {
            this.instituciones = res.data;

            this.instituciones.splice(0, 0, {
              nombreInstitucion: "TODAS",
              id: 0,
              sigla: "Todas las instituciones",
              estadosId: 1,
              restrictiva: "N",
              estado: "Activo"
            });
          }
          this.ddInstitucionesLoading = false;
        })
        .catch(() => {
          this.instituciones = [];
          this.ddInstitucionesLoading = false;
        });
    },

    //Obtener tipos de datos financieros
    async obtenerPeriodos() {
      this.periodos = [];

      this.ddPeriodosLoading = true;

      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "Periodos/all/1" })
        .then(res => {
          if (res.status === 200) {
            this.periodos = res.data;
            this.periodos.splice(0, 0, {
              periodo: "TODOS",
              id: 0,
              estadosId: 1,
              estado: "Activo"
            });
          }
          this.ddPeriodosLoading = false;
        })
        .catch(() => {
          this.periodos = [];
          this.ddPeriodosLoading = false;
        });
    },

    //Bimestres
    async obtenerBimestres() {
      this.ddBimestresLoading = true;
      this.bimestres = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: "Bimestre/all/1" })
        .then(res => {
          if (res.status === 200) {
            this.bimestres = res.data;
            this.bimestres.splice(0, 0, {
              nombreBimestre: "TODOS",
              id: 0,
              estadosId: 1,
              estado: "Activo"
            });
          }
          this.ddBimestresLoading = false;
        })
        .catch(() => {
          this.bimestres = [];
          this.ddBimestresLoading = false;
        });
    },

    async buscarIaff() {
      this.consultaFinalizada = false;

      /*if (
        this.nombreProyecto.length <= 0 &&
        this.codigoProyecto.length <= 0 &&
        !this.entidadSeleccionada &&
        !this.fuenteCooperanteSeleccionada
      ) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          "Es necesario ingresar un código de proyecto, nombre del proyecto, institución o fuente cooperante para realizar la búsqueda"
        );
        return;
      }*/

      this.btnBusquedaLoading = true;

      if (this.nombreProyecto == null) {
        this.datosBusqueda.nombreProyecto = "";
      } else {
        this.datosBusqueda.nombreProyecto = this.nombreProyecto;
      }

      if (this.codigoProyecto == null) {
        this.datosBusqueda.codigo = "";
      } else {
        this.datosBusqueda.codigo = this.codigoProyecto;
      }

      if (!this.entidadSeleccionada) {
        this.datosBusqueda.entidadId = 0;
      } else {
        this.datosBusqueda.entidadId = this.entidadSeleccionada.id;
      }

      //Verificar si la fuente es bilateral
      if (!this.fuenteCooperanteSeleccionada) {
        this.datosBusqueda.fuenteCooperanteId = 0;
        //console.log("here")
      } else {
        if (this.fuenteCooperanteSeleccionada.tipoFuenteCooperante === "B") {
          this.datosBusqueda.fuenteCooperanteId = this.fuenteCooperanteSeleccionada.idFuenteBi;
          this.datosBusqueda.tipoFuenteCooperante = "B";
        }

        if (this.fuenteCooperanteSeleccionada.tipoFuenteCooperante === "M") {
          this.datosBusqueda.fuenteCooperanteId = this.fuenteCooperanteSeleccionada.idFuenteMulti;
          this.datosBusqueda.tipoFuenteCooperante = "M";
        }

        if (this.fuenteCooperanteSeleccionada.tipoFuenteCooperante === "N") {
          this.datosBusqueda.fuenteCooperanteId = 0;
        }
      }

      //this.ddPeriodosLoading=true;

      await this.$store
        .dispatch(REPORTE_DOCUEMNTOS_CNS, this.datosBusqueda)
        .then(res => {
          if (res.status === 200) {
            this.proyectos = res.data;
            this.busqueda = true;
            this.consultaFinalizada = true;
          } else {
            this.busqueda = false;
          }
          this.tableLoading = false;
          this.btnBusquedaLoading = false;
        })
        .catch(() => {
          this.proyectos = [];
          this.btnBusquedaLoading = false;
          this.tableLoading = false;
          //this.consultaFinalizada=false;
        });
      /*entidadSeleccionada
fuenteCooperanteSeleccionada)*/
    },

    limpiarFiltros() {
      this.codigoProyecto = "";
      this.entidadSeleccionada = null;
      this.fuenteCooperanteSeleccionada = null;
      this.nombreProyecto = "";
      this.datosBusqueda.tipoConsulta = 1;
    },

    //!Descargar el archivo de respaldo
    descargarArchivo(path, tipo) {
      /* let newPath = path.replace(/\\/g, '/');
            //console.log(newPath);
            window.open("http://localhost:5236"+ newPath, "_blank"); */

      if (tipo == "sigeaci") DownloadFile.downloadSigeaci(path);

      if (tipo == "sicoopera") DownloadFile.download(path);

      if (tipo == "externo") DownloadFile.download(path);
    },

  },

  created() {
    this.obtenerEntidades();
    this.obtenerFuentesCooperantes();
    this.obtenerPeriodos();
    this.obtenerBimestres();
    this.limpiarFiltros();
  },

  watch: {
    codigoProyecto: function() {
      this.codigoProyecto == null
        ? (this.codigoProyecto = "")
        : (this.codigoProyecto = this.codigoProyecto);
    },

    tipoConsulta: function() {
      this.tipoConsulta == null
        ? (this.tipoConsulta = 0)
        : (this.tipoConsulta = this.tipoConsulta);
    },

    /*entidadSeleccionada : function(){
            this.entidadSeleccionada == null ? this.entidadSeleccionada = 0 : this.entidadSeleccionada = this.entidadSeleccionada;
        },

        fuenteCooperanteSeleccionada : function(){
            this.fuenteCooperanteSeleccionada == null ? this.fuenteCooperanteSeleccionada = 0 : this.fuenteCooperanteSeleccionada = this.fuenteCooperanteSeleccionada;
        },*/
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "/" },
      { title: "Consultas" },
      { title: "Documentos Proyectos" }
    ]);

    // var ciphertext = this.$CryptoJS.AES.encrypt("2", 'KEYADMINCNS2022');
  },

  computed: {
    headersBimestreAnio() {
      return [
        {
          text: "Código SICOOPERA",
          align: "start",
          sortable: false,
          value: "codigo"
        },
        {
          text: "Código SIGEACI",
          align: "start",
          sortable: false,
          value: "refSigeaci"
        },
        {
          text: "Anio",
          align: "start",
          sortable: false,
          value: "periodo"
        },
        {
          text: "Bimestre",
          align: "start",
          sortable: true,
          value: "bimestre"
        },
        {
          text: "Nombre del proyecto",
          align: "start",
          sortable: true,
          value: "nombreProyecto"
        },
        {
          text: "Fuente Cooperante",
          align: "start",
          sortable: true,
          value: "fuenteCooperante"
        },
        {
          text: "Institución Ejecutora",
          align: "start",
          sortable: true,
          value: "institucion"
        },
        {
          text: "Fecha suscripción",
          align: "start",
          sortable: true,
          value: "fechaSuscripcion"
        },
        {
          text: "Fecha inicio",
          align: "start",
          sortable: true,
          value: "fechaInicio"
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estadoProyecto",
          filter: this.filtroEstado
        },
        {
          text: "Exportar",
          align: "start",
          sortable: false,
          value: ""
        },
      ];
    },

    headers() {
      return [
        {
          text: "Código SICOOPERA",
          align: "start",
          sortable: false,
          value: "codigo"
        },
        {
          text: "Nombre del proyecto",
          align: "start",
          sortable: true,
          value: "nombreProyecto"
        },
        {
          text: "Fuente Cooperante",
          align: "start",
          sortable: true,
          value: "fuenteCooperante"
        },
        {
          text: "Institución Ejecutora",
          align: "start",
          sortable: true,
          value: "institucion"
        },
        {
          text: "Fecha suscripción",
          align: "start",
          sortable: true,
          value: "fechaSuscripcion"
        },
        {
          text: "Fecha inicio",
          align: "start",
          sortable: true,
          value: "fechaInicio"
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estadoProyecto",
          filter: this.filtroEstado
        },
        {
          text: "Exportar",
          align: "start",
          sortable: false,
          value: ""
        },
      ];
    }
  }
};
</script>
<style lang=""></style>
